@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    font-family: 'Roboto', sans-serif;
  }
  li {
    @apply p-4
  }

  button {
    /* @apply text-white border bg-[#53900f] border-[#53900f] */
    /* hover:bg-transparent hover:text-[#53900f] rounded-md */
    @apply text-white border-[2.5px] bg-transparent border-white
    hover:bg-white hover:text-gray-800 rounded-md
  }
}